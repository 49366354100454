import React from "react";
import "./Slider.css";

export default class Slider extends React.Component {
    state = {
        value: 0,
    };

    map = new Map([
        ["-3", "Stimme absolut nicht zu"],
        ["-2", "Stimme nicht zu"],
        ["-1", "Kann nicht voll zustimmen"],
        ["0", "Neutral"],
        ["1", "Stimme leicht zu"],
        ["2", "Stimme zu"],
        ["3", " Stimme voll und ganz zu"]
    ]);

    handleSliderOnChange = e => {
        this.setState({ value: e.target.value });
        this.props.onChange(e);
    };

    render() {
        return (
            <div className="slider-container">
                <div className="slider-value">
                    {this.state.value
                        ? this.map.get(this.state.value)
                        : this.map.get("0")
                    }
                </div>
                <div className="slider">
                    <input
                        className="slider"
                        list="steplist"
                        type="range"
                        min={-3}
                        max={3}
                        step="1"
                        value={this.state.value}
                        onChange={this.handleSliderOnChange}
                    />
                    <datalist id="steplist">
                        <option className="end-point-left"></option>
                        <option></option>
                        <option></option>
                        <option></option>
                        <option></option>
                        <option></option>
                        <option className="end-point-right"></option>
                    </datalist>
                </div>
            </div>
        );
    }
}

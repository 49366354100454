import React from "react";
import './SegmentedControl.css';

function SegmentedControl(props) {
    return (
        <div className="matching-types-wrapper">
            <div className="matching-types">
                <input type="radio" name="matching-type" value="-3" id="-3" onClick={props.onClick}/>
                <label htmlFor="-3">Stimme absolut nicht zu</label>

                <input type="radio" name="matching-type" value="-2" id="-2" onClick={props.onClick}/>
                <label htmlFor="-2">Stimme nicht zu</label>

                <input type="radio" name="matching-type" value="-1" id="-1" onClick={props.onClick}/>
                <label htmlFor="-1">Kann nicht voll zustimmen</label>

                <input type="radio" name="matching-type" value="0" id="0" onClick={props.onClick}/>
                <label htmlFor="0">Neutral</label>

                <input type="radio" name="matching-type" value="1" id="1" onClick={props.onClick}/>
                <label htmlFor="1">Stimme leicht zu</label>

                <input type="radio" name="matching-type" value="2" id="2" onClick={props.onClick}/>
                <label htmlFor="2">Stimme zu</label>

                <input type="radio" name="matching-type" value="3" id="3" onClick={props.onClick}/>
                <label htmlFor="3">Stimme voll und ganz zu</label>
            </div>
        </div>
    )
}

export default SegmentedControl;
import React, { Fragment } from 'react';
import { Breakpoint, BreakpointProvider } from 'react-socks';
import * as questionsJson from '../Questions.json';
import './Question.css';
import TopBar from '../components/TopBar';
import ProgressBar from '../components/ProgressBar';
import SegmentedControl from '../components/SegmentedControl';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom'


const questionsJSON = shuffle([...questionsJson.default]);

export default class Question extends React.Component {

    INITIAL_STATE = {
        sliderMatchingValue: "0",
        questions: questionsJSON,
        index: 0,
        actualQuestion: questionsJSON[0],
        answeredQuestions: [],
        resultList: []
    }

    constructor(props) {
        super(props);
        this.state = this.INITIAL_STATE;
    }

    handleSelectedSegmentedControlValue = (event) => {
        event.persist();
        this.setState(() => {
            return {
                index: this.state.index + 1,
                actualQuestion: this.state.questions[this.state.index + 1],
                answeredQuestions: this.state.answeredQuestions.concat({ type: this.state.actualQuestion.type, id: this.state.actualQuestion.id, value: event.target.value, label: this.state.actualQuestion.label })
            }
        },
        );
    }

    handleSelectedSliderValue = () => {
        this.setState(() => {
            return {
                index: this.state.index + 1,
                actualQuestion: this.state.questions[this.state.index + 1],
                answeredQuestions: this.state.answeredQuestions.concat({ type: this.state.actualQuestion.type, id: this.state.actualQuestion.id, value: this.state.sliderMatchingValue, label: this.state.actualQuestion.label })
            }
        },
        )
    }

    setSliderMatchingValue = (event) => {
        event.persist();
        this.setState(() => {
            return {
                sliderMatchingValue: event.target.value
            }
        })
    }

    handleWindowBeforeUnload = () => {
        if (this.state.answeredQuestions.length !== 0 && !localStorage.getItem('status')) {
            localStorage.setItem('answeredQuestions', JSON.stringify(this.state.answeredQuestions));
        }
    }

    render() {
        var getAnsweredQuestions = JSON.parse(localStorage.getItem('answeredQuestions'));
        var answeredLength;
        if (getAnsweredQuestions) {
            answeredLength = getAnsweredQuestions.length + this.state.index;
        } else {
            answeredLength = this.state.index;
        }

        if (answeredLength === questionsJSON.length || localStorage.getItem('emailStatus') === 'sended') {
            localStorage.setItem('finished', true);
            if (localStorage.getItem('status') !== 'completed') {
                localStorage.setItem('status', 'finished');
            }
            return <Redirect to='/result' />
        }

        return (
            <Fragment>
                <TopBar />

                <ProgressBar actualQuestionIndex={answeredLength}></ProgressBar>

                <div className="question-wrapper">
                    <div className="question">
                        {this.state.actualQuestion.label}
                    </div>

                    <BreakpointProvider>
                        <Breakpoint customQuery="(max-width: 1024px)" className="breakpoint">
                            <Slider onChange={this.setSliderMatchingValue} />
                        </Breakpoint>

                        <Breakpoint customQuery="(min-width: 1025px)" className="breakpoint">
                            <SegmentedControl onClick={this.handleSelectedSegmentedControlValue} />
                        </Breakpoint>
                    </BreakpointProvider>
                </div>

                <BreakpointProvider>
                    <Breakpoint customQuery="(max-width: 1024px)" className="breakpoint">
                        <Footer onNextClick={this.handleSelectedSliderValue} />
                    </Breakpoint>
                </BreakpointProvider>

            </ Fragment>
        )
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);

        var getAnsweredQuestions = JSON.parse(localStorage.getItem('answeredQuestions'));
        if (getAnsweredQuestions) {
            var filteredList = this.state.questions.filter((question) => {
                return getAnsweredQuestions.findIndex(answeredQuestions => answeredQuestions.id === question.id) === -1
            })

            this.setState(() => {
                return {
                    questions: filteredList,
                    actualQuestion: filteredList[0],
                    answeredQuestions: getAnsweredQuestions
                }
            },
            );
        }
    }

    componentWillUnmount() {
        if (this.state.answeredQuestions.length !== 0 && localStorage.getItem('status') !== 'completed') {
            localStorage.setItem('answeredQuestions', JSON.stringify(this.state.answeredQuestions));
        }
    }
}

function shuffle(arr) {
    var i,
        j,
        temp;
    for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    return arr;
};

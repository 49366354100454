import React from 'react';
import './ProgressBar.css';
import * as questionsJson from '../Questions.json';

const questionsJSON = [...questionsJson.default];

export default class ProgressBar extends React.Component {
    render() {
        return (
            <div className="progressbar-wrapper">
                <div className="progressbar-text-wrapper">
                    <p className="progressbar-text">Matching-Fragebogen</p>
                    <p className="progressbar-text">{this.props.actualQuestionIndex + 1} / {questionsJSON.length}</p>
                </div>
                <progress value={this.props.actualQuestionIndex} max={questionsJSON.length -1}
                ></progress>
            </div>
        )
    }
}
import React, { Fragment } from 'react';
import TopBar from '../components/TopBar';
import './Info.css';
import * as createHistory from "history";

const history = createHistory.createBrowserHistory();

export default class Info extends React.Component {
    render() {
        return (
            <Fragment>
                <TopBar
                    info={true}
                />
                <div className="body">
                    <div className="body-text">
                        <p className="header">Informationen zum Fragebogen</p>
                        <p className="text">Um Dich kennenzulernen und zu erfahren, was Dich motiviert und antreibt, benötigen wir Deine Hilfe, die Beantwortung der Aussagen. Wichtig: Beantworte jede ehrlich und intuitiv.  Bitte antworte nicht, wie Du glaubst, dass es vielleicht von anderen erwartet wird oder richtig sein könnte. Es gibt keine richtigen oder falschen Antworten.</p>
                        <p className="subheader">Dein Nutzen</p>
                        <p className="text">Wenn Du Deine Motive genauer kennenlernst, gewinnst Du wertvolle Einblicke in die eigene Persönlichkeit: Was bewegt Dich im Innersten und treibt Dich an. Dies trägt dazu bei, dass Du zukünftig ebenfalls noch mehr auf Deine Antreiber achtest und leistungsfähiger und motivierter arbeitest. <br></br> In jedem Menschen kommen Motive in einer gewissen Ausprägung vor. Dennoch unterscheiden sich diese individuell in der Stärke der Ausprägung und in der Motiv-Konstellation - Dein individueller Fingerabdruck des "Kerns Deiner Persönlichkeit". </p>
                    </div>
                    <button className="back-button" onClick={history.goBack}>Zurück</button>
                </div>
            </Fragment>
        )
    }
}
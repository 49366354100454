import React from 'react';
import './Footer.css';

function Footer(props) {
    return (
        <footer className="footer-wrapper">
            <button className="footer-next-button" onClick={props.onNextClick}>
                <div className="inner-next-button"></div>
            </button>
        </footer>
    )
}

export default Footer;
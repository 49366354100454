import React, { Fragment } from 'react';
import TopBar from '../components/TopBar';
import resultImage from '../assets/images/result.jpg';
import './Result.css';
import emailjs from 'emailjs-com';
export default class Result extends React.Component {

    displayList = [];

    infoList = [
        { type: 'control', negative: 'Erbringung von Dienstleistung', positive: 'Übernahme von Verantwortung' },
        { type: 'ownResponsibility', negative: 'Teamorientierung und Gemeinschaft', positive: 'Eigenverantwortlichkeit und Freiheit' },
        { type: 'knowledge', negative: 'praktische Neugier', positive: 'Wissenserwerb' },
        { type: 'recognition', negative: 'Direktheit und offene Kritik', positive: 'Bestätigung eines positiven Selbstbildes' },
        { type: 'processAndStructuralStability', negative: 'Flexibilität und Spontanität', positive: 'Prozess- und Strukturstabilität' },
        { type: 'collectThings', negative: 'Großzügigkeit', positive: 'Dinge zu sammeln' },
        { type: 'morality', negative: 'Ziel- und Zweckorientierung', positive: 'Orientierung an Prinzipien' },
        { type: 'socialFairness', negative: 'Realismus', positive: 'soziale Gerechtigkeit' },
        { type: 'society', negative: 'Zurückgezogenheit', positive: 'Gesellschaft' },
        { type: 'socialRank', negative: 'Ausleben der eigenen Bescheidenheit', positive: 'gesellschaftlicher Rang' },
        { type: 'competition', negative: 'Einklang und Ausgleich', positive: 'Vergleich und Ranking' },
        { type: 'powerAndAgility', negative: 'Gemütlichkeit', positive: 'Bewegung' },
        { type: 'safetyAndStability', negative: 'Abwechslung und Veränderung', positive: 'Sicherheit und emotionale Stabilität' }
    ]

    INITIAL_STATE = {
        resultList: [],
        allMaxOrMinDesireObjects: [],
        bestDesires: [],
    }

    constructor(props) {
        super(props);
        this.state = this.INITIAL_STATE;
    }


    financial(x) {
        return Number.parseFloat(x).toFixed(1);
    }


    calculateResult = async (list) => {
        var controlList = [];
        var ownResponsibilityList = [];
        var knowledgeList = [];
        var recognitionList = [];
        var processAndStructuralStabilityList = [];
        var collectThingsList = [];
        var moralityList = [];
        var socialFairnessList = [];
        var societyList = [];
        var socialRankList = [];
        var competitionList = [];
        var powerAndAgilityList = [];
        var safetyAndStabilityList = [];

        if (list && list.length > 0) {
            list.forEach(element => {
                switch (element.type) {
                    case 'ownResponsibility':
                        ownResponsibilityList.push(element.value)
                        break
                    case 'control':
                        controlList.push(element.value)
                        break
                    case 'knowledge':
                        knowledgeList.push(element.value)
                        break
                    case 'recognition':
                        recognitionList.push(element.value)
                        break
                    case 'processAndStructuralStability':
                        processAndStructuralStabilityList.push(element.value)
                        break
                    case 'collectThings':
                        collectThingsList.push(element.value)
                        break
                    case 'morality':
                        moralityList.push(element.value)
                        break
                    case 'socialFairness':
                        socialFairnessList.push(element.value)
                        break
                    case 'society':
                        societyList.push(element.value)
                        break
                    case 'socialRank':
                        socialRankList.push(element.value)
                        break
                    case 'competition':
                        competitionList.push(element.value)
                        break
                    case 'powerAndAgility':
                        powerAndAgilityList.push(element.value)
                        break
                    case 'safetyAndStability':
                        safetyAndStabilityList.push(element.value)
                        break
                    default:
                        break
                }
            });

            // control
            const computeSingleResult = (list) => {
                const result = list.reduce((accumulator, current) => {
                    return parseInt(accumulator) + parseInt(current);
                }, '0')
                return controlList.length && this.financial(result / list.length);
            }
            const resultList = [
                { type: "control", value: computeSingleResult(controlList)  },
                { type: "ownResponsibility", value: computeSingleResult(ownResponsibilityList)  },
                { type: "knowledge", value: computeSingleResult(knowledgeList)  },
                { type: "recognition", value: computeSingleResult(recognitionList)  },
                { type: "processAndStructuralStability", value: computeSingleResult(processAndStructuralStabilityList)  },
                { type: "collectThings", value: computeSingleResult(collectThingsList)  },
                { type: "morality", value: computeSingleResult(moralityList)  },
                { type: "socialFairness", value: computeSingleResult(socialFairnessList)  },
                { type: "society", value: computeSingleResult(societyList)  },
                { type: "socialRank", value: computeSingleResult(socialRankList)  },
                { type: "competition", value: computeSingleResult(competitionList)  },
                { type: "powerAndAgility", value: computeSingleResult(powerAndAgilityList)  },
                { type: "safetyAndStability", value: computeSingleResult(safetyAndStabilityList)  },
            ].filter(item => item.value !== 'NaN');
            this.state.resultList = resultList;
            console.log('state', { state: this.state, resultList });

        }
    }

    isMaxOrMin(currentObj) {
        return currentObj.value === "3.0" || currentObj.value === "-3.0"
    }

    setBestDesire = (list) => {

        let firstMaxValue;
        let lastMaxValue;

        // get the first max value desire and add it to the bestDesire list
        list.reduce((prevObj, currentObj) => {
            //first best max desire
            firstMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
            return firstMaxValue;
        })
        this.state.bestDesires.push({ type: firstMaxValue.type, value: firstMaxValue.value });


        //filter the firstMaxValue
        var filteredFirstMaxValueList = list.filter((obj) => {
            return obj.type !== firstMaxValue.type
        })

        // get the second max value desire and add it to the bestDesire list
        const secondMaxValue = filteredFirstMaxValueList.reduce((prevObj, currentObj) =>
            //second best max desire
            (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj, {})
        this.state.bestDesires.push({ type: secondMaxValue.type, value: secondMaxValue.value });


        //filter the secondMaxValue
        var filteredSecondMaxValueList = filteredFirstMaxValueList.filter((obj) => {
            return obj.type !== secondMaxValue.type
        })

        // get the last max value desire and add it to the bestDesire list
        filteredSecondMaxValueList.reduce((prevObj, currentObj) => {
            //last best max desire
            lastMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
            return lastMaxValue;
        })
        this.state.bestDesires.push({ type: lastMaxValue.type, value: lastMaxValue.value });

    }

    getDesireList = (list) => {
        if (list.find(this.isMaxOrMin)) {
            list.forEach((currentObj) => {
                if (currentObj.value === "3.0" || currentObj.value === "-3.0") {
                    this.state.allMaxOrMinDesireObjects.push({ type: currentObj.type, value: currentObj.value });
                }
            })

            let firstMaxValue;
            let secondMaxValue;
            let thirdMaxValue;

            const findedMaxOrMinDesireObjectLength = this.state.allMaxOrMinDesireObjects.length;
            if (findedMaxOrMinDesireObjectLength < 3) {
                if (findedMaxOrMinDesireObjectLength === 1) {

                    //find the first max or min desire object
                    list.reduce((prevObj, currentObj) => {
                        firstMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return firstMaxValue;
                    })
                    //filter the first max or min desire object
                    var filteredFirstMaxValueList = list.filter((obj) => {
                        return obj.type !== firstMaxValue.type
                    })
                    // find the second max or min desire object
                    filteredFirstMaxValueList.reduce((prevObj, currentObj) => {
                        secondMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return secondMaxValue;
                    })
                    //add the second max or min desire object to the result list
                    this.state.allMaxOrMinDesireObjects.push({ type: secondMaxValue.type, value: secondMaxValue.value });
                    // filter the second max or min desire object 
                    var filteredSecondMaxValueList = filteredFirstMaxValueList.filter((obj) => {
                        return obj.type !== secondMaxValue.type
                    })
                    // find the third max or min desire object
                    filteredSecondMaxValueList.reduce((prevObj, currentObj) => {
                        thirdMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return thirdMaxValue;
                    })
                    // add the third max or min desire object to the result list
                    this.state.allMaxOrMinDesireObjects.push({ type: thirdMaxValue.type, value: thirdMaxValue.value });

                } else {
                    //find the first max or min desire object
                    list.reduce((prevObj, currentObj) => {
                        firstMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return firstMaxValue;
                    })

                    //filter the first max or min desire object
                    var filteredFirstList = list.filter((obj) => {
                        return obj.type !== firstMaxValue.type
                    })

                    // find the second max or min desire object
                    filteredFirstList.reduce((prevObj, currentObj) => {
                        secondMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return secondMaxValue;
                    })
                    //filter the second max or min desire object
                    var filteredSecondList = filteredFirstList.filter((obj) => {
                        return obj.type !== secondMaxValue.type
                    })

                    //find the third max or min desire object
                    filteredSecondList.reduce((prevObj, currentObj) => {
                        thirdMaxValue = (Math.abs(prevObj.value) >= Math.abs(currentObj.value)) ? prevObj : currentObj;
                        return thirdMaxValue;
                    })
                    this.state.allMaxOrMinDesireObjects.push({ type: thirdMaxValue.type, value: thirdMaxValue.value });
                }
            }

            this.state.allMaxOrMinDesireObjects.slice(0, 3).map(obj =>
                this.infoList.forEach((current) => {
                    if (current.type === obj.type) {
                        obj.value < 0 ? this.displayList.push({ type: obj.type, value: current.negative }) : this.displayList.push({ type: obj.type, value: current.positive })
                    }
                })
            )

            return <React.Fragment>
                {this.displayList.map(desire =>
                    < div key={desire.type} >
                        {desire.value}
                    </div>
                )}
            </React.Fragment >

        } else {
            this.setBestDesire(list);

            this.state.bestDesires.map(obj =>

                this.infoList.forEach((current) => {
                    if (current.type === obj.type) {
                        obj.value < 0 ? this.displayList.push({ type: obj.type, value: current.negative }) : this.displayList.push({ type: obj.type, value: current.positive })
                    }
                })

            )
            return <React.Fragment>
                {this.displayList.map(desire =>
                    < div key={desire.type} >
                        {desire.value}
                    </div>
                )}
            </React.Fragment >
        }

    }

    render() {
        let header;
        let text;
        let showDesire;

        if (localStorage.getItem('status') === 'finished') {
            header = (<div>
                <p className="header">Schön, dass Du Dir Zeit genommen hast!</p>
            </div>);
            text = (<div>
                        <p className="text">Das Bedürfnis nach Dienstleistungsorientierung oder nach Übernahme von Verantwortung und das Bedürfnis nach Teamwork-, Teamorientierung oder nach Eigenverantwortlichkeit und Freiheit bestimmen Deinen intrinsischen Führungsstil. Wir begleiten Führungskräfte dabei, wie sie diese gezielt und erfolgreich in der Führung einsetzen.</p>
                        <p className="text">Die folgenden Bedürfnisse beeinflussen wiederum Deine psychische Stabilität:</p>
                        <ul className="psychic-stability">
                            <li>Nach Direktheit oder Bestätigung eines positiven Selbstbildes</li>
                            <li>Nach Harmonie oder Wettkampf und Vergleich</li>
                            <li>Nach Abwechslung und Abenteuer oder Sicherheit und Stabilität.</li>
                        </ul>
                        <p className="text">Neugierig geworden? Erfahre gern mehr, welche der 16 bipolaren Lebensmotive Deine besonderen Leistungsmotive sind und wie Du diese gewinnbringend und erfolgreich in der Zukunft nutzen kannst.</p>
            </div>);
            showDesire = this.state.resultList.length > 0
                ? <div className="desire-wrapper">
                    Deine stärksten Bedürfnisse sind:
                <div className="desire">{this.getDesireList(this.state.resultList)}</div>
                </div>

                : null
        } else {
            header = <p className="header">Du hast bereits alle Fragen beantwortet.</p>
            text = <p className="text">Wir werden das Ergebnis auswerten und uns für ein Gespräch melden, wo wir näher auf das Ergebnis eingehen werden.</p>
        }
        return (
            <Fragment>
                <TopBar />
                <div className="body">
                    <img className="result-image" src={resultImage} alt="result"></img>
                    <div className="body-text">
                        {header}
                        {showDesire}
                        {text}
                        {/* <p className="text">In der Zwischenzeit folge uns doch gern bei LinkedIn oder Facebook:</p> */}
                    </div>
                    <div className="social-media">
                        <a href="https://www.linkedin.com/company/kompetenzmagnet" className="linkedin" > </a>
                        <a href="https://www.instagram.com/kompetenzmagnet/" className="instagram" > </a>
                    </div>
                </div>
            </Fragment>
        );
    }

    componentWillUnmount() {
        localStorage.setItem('status', 'completed');
    }

    componentDidMount() {
        const answeredQuestionList = JSON.parse(localStorage.getItem('answeredQuestions'));
        this.calculateResult(answeredQuestionList);
        localStorage.setItem('resultList', JSON.stringify(this.state.resultList));

        this.setState({
            resultList: JSON.parse(localStorage.getItem('resultList'))
        })

        if (!localStorage.getItem('emailStatus')) {
            const resultList = JSON.parse(localStorage.getItem('resultList'))
            const sendList = resultList.reduce((list, obj) => {
                const infoItem = this.infoList.find((infoItem) => infoItem.type === obj.type);
                if (!infoItem) {
                    // type not found
                    return list;
                }
                if (obj.value < 0) {
                    return [...list, { description: infoItem.negative, value: obj.value }];
                } else {
                    return [...list, { description: infoItem.positive, value: obj.value }];
                }
            }, []);
            const sendText = sendList.reduce((text, item) => `${text}${item.description}: ${item.value}, `, '');

            //Havva
            // emailjs.send(
            //     'outlook',
            //     'template_8CqzHe7z',
            //     { message_html: sendText, from_name: localStorage.getItem('userId') },
            //     'user_0OSBK8fspjriYLQiEfXm3'
            // ).then(res => {
            //     localStorage.setItem('emailStatus', 'sended');
            //     localStorage.removeItem('answeredQuestions');
            //     localStorage.removeItem('resultList');
            //     localStorage.removeItem('userId');
            // })
            //     .catch(err => console.error('Beim Versenden der E-Mail ist ein Fehler aufgetreten:', err))

            emailjs.send(
                'outlook',
                'template_UnSvT7PP',
                { message_html: sendText, from_name: localStorage.getItem('userId') },
                'user_QnHuI0vDLRdKsQ3QzUk7c'
            ).then(res => {
                console.log('Email erfolgreich gesendet!', { message_html: sendText, from_name: localStorage.getItem('userId'), res })
                localStorage.setItem('emailStatus', 'sended');
                localStorage.removeItem('answeredQuestions');
                localStorage.removeItem('resultList');
                localStorage.removeItem('userId');
            })
                .catch(err => console.error('Beim Versenden der E-Mail ist ein Fehler aufgetreten:', err))
        }
    }

}
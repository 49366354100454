import React from 'react';
import './App.css';
import Intro from './containers/Intro';
import Info from './containers/Info';
import Result from './containers/Result';
import './index.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import {
  Switch,
  Route,
} from "react-router";
import Question from './containers/Question';

function App() {

  return (
    <Router>
      <div className="app-content">
        <Switch>
          <Route path="/info">
            <Info />
          </Route>
          <Route path="/question">
            <Question />
          </Route>
          <Route path="/result">
            <Result />
          </Route>
          <Route path="/" render={props => <Intro {...props} />}></Route>
        </Switch>
      </div>
    </Router >
  );
}

export default App;

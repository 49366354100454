import React from 'react';
import logo from '../assets/images/logo.png';
import './TopBar.css';
import { Link } from 'react-router-dom';
import * as createHistory from "history";
import closeButtonHover from '../assets/images/closeIconHover.png';

const history = createHistory.createBrowserHistory();

export default class TopBar extends React.Component {

    state = {
        hover: false,
    };

    render() {
        let closeButton;
        let infoButton;

        if (this.props.info) {
            closeButton =
                <button
                    onMouseEnter={() => {
                        this.setState({ hover: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ hover: false });
                    }}
                    className="close-button"
                    onClick={history.goBack}
                    style={this.state.hover ? { color: '#3DB7E4', border: '4px solid #3DB7E4' } : {}}>
                    <div
                        className="close-icon"
                        style={this.state.hover ? { backgroundImage: `url(${closeButtonHover})`, width: '29px', height: '29px' } : {}}>
                    </div>
                </button>
        } else {
            infoButton =
                <div>
                    <Link to='/info'
                        onMouseEnter={() => {
                            this.setState({ hover: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hover: false });
                        }}
                        className="info desktop"
                        style={this.state.hover ? { color: '#3DB7E4', border: '4px solid #3DB7E4' } : {}}>info</Link>
                    <Link to='/info'
                        onMouseEnter={() => {
                            this.setState({ hover: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hover: false });
                        }}
                        className="info mobile"
                        style={this.state.hover ? { color: '#3DB7E4', border: '4px solid #3DB7E4' } : {}}>i</Link>
                </div>
        }

        return (
            <header className="header">
                <img src={logo} alt="Icon"></img>
                {infoButton}
                {closeButton}
            </header>
        )
    }
}

import React, { Fragment } from 'react';
import TopBar from '../components/TopBar';
import './Intro.css';
import { NavLink } from 'react-router-dom';
import introImage from '../assets/images/intro.jpg';


export default class Intro extends React.Component {

    state = {
        privacyPolicy: false,
    }

    onChange = () => {
        this.setState({ privacyPolicy: !this.state.privacyPolicy })
    }
    handleNavLinkClickEvent = (event) => {
        this.setState({ clicked: true })
        if (this.state.privacyPolicy) {
            localStorage.setItem('privacyPolicy', true);
            return;
        }
        event.preventDefault();
    }

    render() {
        let info;
        if (!this.state.privacyPolicy && this.state.clicked) {
            info = <div className="info-text">Bitte bestätige, dass Du die Infos zum Datenschutz gelesen und akzeptiert hast!</div>
        }
        return (
            <Fragment>
                <TopBar />
                <div className="body">
                    <img className="intro-image" src={introImage} alt="intro"></img>
                    <div className="body-text">
                        {/* <p className="header">Herzlich Willkommen bei Deinem Kompetenzmagnet(en).</p> */}
                        <p className="text">Möchtest Du einen kleinen Ausschnitt über Deine persönlichen Leistungsmotive und Bedürfnisse in der Führung und zur Stress Resilienz erfahren? Dann nimm Dir 5 Minuten Deiner Zeit zur Beantwortung der folgenden Fragen.<br />Bitte beantworte die folgenden Fragen intuitiv und ohne großes Nachdenken.<br /><br />Viel Spass</p>
                        <div className="privacy-policy-wrapper">
                            <input type="checkbox" id="privacy-policy" name="privacy-policy" onChange={this.onChange} checked={this.state.privacyPolicy} />
                            <label htmlFor="privacy-policy"> Ich habe die &nbsp;<a href="https://kompetenzmagnet.de/datenschutzerklaerung/">Infos zum Datenschutz</a> &nbsp;gelesen und bin damit einverstanden.</label>
                        </div>
                        {info}
                    </div>
                    <NavLink className="button" to="/question" onClick={this.handleNavLinkClickEvent}> Jetzt loslegen </NavLink>
                </div>
            </Fragment>
        )
    }

    componentDidMount() {
        const userId = this.props.location.pathname.substring(1, this.props.location.pathname.length)
        localStorage.setItem('userId', userId);
        const localStoragePrivacyPolicy = localStorage.getItem('privacyPolicy') === 'true';
        this.setState(() => {
            return {
                privacyPolicy: localStoragePrivacyPolicy,
            }
        })
    }
}